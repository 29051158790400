.layer {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 100%;
    height: 100vh;
    background-color: white;
    top: 0;
    z-index: 11;
  }
  .animation {
    animation:rotation .8s infinite linear;
  }
  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
  